import { useEffect } from "react";

const IndexPage = () => {
  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser && window.location.pathname === "/") {
      window.location.pathname = `/se/`;
    }
  }, [isBrowser]);

  return null;
};

export default IndexPage;
